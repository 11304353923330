.TrialInfoModal {
  ul {
    padding: 0;
    list-style-type: none;
    //list-style-position: outside;
    text-indent: -20px; /* key property */
    margin-left: 20px; /* key property */

    li {
      margin-top: 5px;
      margin-bottom: 15px;
    }

    li::before{
      content: "\f061";
      font-family: "FontAwesome";
      color: #48a868;
      padding: 0 10px 0 0;
    }
  }


}
