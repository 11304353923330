$base-width: 60px;
$base-height: 36px;
$slider-height: 13px;
$slider-width: 13px;
$slide-width: 26px;

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: $base-width;
    height: $base-height;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cccccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: $slider-height;
    width: $slider-width;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX($slide-width);
    -ms-transform: translateX($slide-width);
    transform: translateX($slide-width);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.sm {
    font-size: .7rem;

    .switch {
        width: calc($base-width / 3);
        height: calc($base-height / 3);
        margin-top: .2rem;
    }

    .slider:before {
        height: calc($slider-height / 1.5);
        width: calc($slider-width / 1.5);
        left: 2px;
        bottom: 2px;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(calc($slide-width / 3));
        -ms-transform: translateX(calc($slide-width / 3));
        transform: translateX(calc($slide-width / 3));
    }
}

.md {
    font-size: .9rem;

    .switch {
        width: calc($base-width / 2);
        height: calc($base-height / 2);
        margin-top: .1rem;
    }

    .slider:before {
        height: calc($slider-height / 1);
        width: calc($slider-width / 1);
        left: 2px;
        bottom: 3px;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(calc($slide-width / 2));
        -ms-transform: translateX(calc($slide-width / 2));
        transform: translateX(calc($slide-width / 2));
    }
}

.lg {
    font-size: 1.5rem;

    .switch {
        width: $base-width;
        height: $base-height;
    }

    .slider:before {
        height: $slider-height*2.2;
        width: $slider-width*2.1;
        left: 4px;
        bottom: 4px;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX($slide-width);
        -ms-transform: translateX($slide-width);
        transform: translateX($slide-width);
    }
}
