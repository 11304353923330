.Config {
  .tab-content {
    margin-top: -25px;
  }

  .Communication {
    textarea {
      min-height: 450px;
    }
  }
}
