.BottomNav {
  height: 80px;
  background-color: #343a40;

  a {
    padding: 0.5em 0.5em 0 0.7em;
    display: block;
    vertical-align: middle;
    text-decoration: none;
    color: #ddd;
  }

  a:hover {
    color: #fff;
  }
}
