.CustomerCard {
    position: relative;
    width: 17rem;
    height: 115px;
    margin-right: .5rem;
    margin-top: 2rem;

    .card-footer {
        background-color: transparent;
        border: none;
    }

    .card-body {
        padding: 0 2.5rem 0 0 !important;
    }

    /* limit the size of the customer name in the cards */
    h5.customer-name {
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 14rem;
        white-space: nowrap;
    }
}

.view-details:hover{
    background-color: #d3e8ff;
    cursor: pointer;
}

.customer-image img {
    top: -25px;
    left: 0;
    right: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    width: 50px;
}

.customer-image img:hover {
    width: 10rem;
}
