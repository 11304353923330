.CardList {
  .strippenkaart.card {
    padding: 0;
  }

    .strippenkaart.collapsed:hover {
        //cursor: pointer;
    }

    .strippenkaart.collapsed .card-body {
        //display: none;
    }

    .strippenkaart.expanded .card-body {
        //display: block;
    }
}
