.avatar {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

body {
  font-size: .875rem;
}

#app-name {
    width: 12rem;
    height: 46px;
    padding-left: 16px;
}

.unit-adder label,
.unit-adder .info-block label,
.info-block label
{
    text-align: left;
}

.customer-header {
    padding: 0 10px 0 1rem;
}

input.error {
    border: 3px solid red;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.feather-100 {
    width: 100px;
    height: 100px;
}

.customer-d-icon {
    width: 65px;
    height: 65px;
    padding: 15px;
    background: #007bff;
    color: #fff;
    border-radius: 30px;
}

.customer-view {
    max-width: 600px;
}

div.errorPanel {
    margin-top: 1rem;
}

div.customerview a svg{
    padding-top: 9px;
}

.customer-stats, .unit-adder {
    max-width: 600px;
}
.react-datepicker-wrapper {
    width: 100%;
}
.klant-card {
    text-align: center;
}

.klant-card-new {
    border: 2px #007bff dotted;
    cursor: pointer;
}

.strippenkaart {
    max-width: 600px;
    box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 1);
    border: 0;
}

a.strippenkaart-anchor {
    display: block;
    visibility: hidden;
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    top: -10px;
}

.cardtable {
    margin-top: 3em;
}

#card-overview tbody tr {
    cursor: pointer;
}

.customer-stats .strips-bought,
.customer-stats .strips-booked{
    color: grey;
}

.card-header .dot.red:hover,
.tooltip-close:hover .tooltiptext {
    visibility: visible;
}

.strippenkaart .card-header .expired
{
    text-decoration: line-through;
}

.strippenkaart .card-header,
.strippenkaart .card-footer {
    border: 0;
    margin: 0;
}

.strippenkaart .card-header .card-settings-button {
    color: grey;
    position: absolute;
    right: 5px;
    top: 5px;
}

.strippenkaart .card-header .card-settings-button:hover {
    color: black;
    cursor: pointer;
    -webkit-animation:spin 3s linear infinite;
    -moz-animation:spin 3s linear infinite;
    animation:spin 3s linear infinite;
}

.strippenkaart, .unitlist {
    /*cursor: pointer;*/
    margin-bottom: 20px;
}

.unitline {
    margin-bottom: 1.6em;
}

/*.strippenkaart .card-body .unitline .row {*/
/*    height: 1.6em;*/
/*}*/

.expired-card {
    color: grey;
}

.strip.used {
    border: 1px solid red;
    background-color: red;
}

.strip.expired {
    border: none;
    color: white;
    font-weight: bolder;
    background: repeating-linear-gradient(
        -55deg,
        #222,
        #222 10px,
        #333 10px,
        #333 20px
    );
}

.strip {
    border: 1px solid red;
    background-color: white;
    text-align: center;
    overflow: hidden;
    margin: 1em;
}

#strip-difference
{
    background-color: transparent;
    border-width: 0;
    border-color: rgba(0,0,0,.42);
    border-radius: 0;
    box-shadow: none;
    /*font-size: 40px;*/
    width: 2em;
    text-align: center;
    color: white;
    font-weight: bolder;
}

.dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
}

.dot.red{
    background-color: red;
}

.dot.grey{
    background-color: lightslategray;
}

.minus:hover, .plus:hover{
    cursor: pointer;
}

.edit-unit {
    left: 5px;
    cursor: pointer;
    color: red;
}

.remove-unit,
.edit-unit {
    cursor: pointer;
    color: lightgrey;
}

.remove-unit:hover{
    display: block;
    color: red;
}

.edit-unit:hover {
    color: #007bff;
}

/**
 * TOOLTIP
 */


.tooltip-close {
    /*position: relative;*/
    /*display: inline-block;*/
    /*border-bottom: 1px dotted black;*/
}

.tooltip-close .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    margin-left: 15px;
}



/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.loginblock {
    display: none;
}


/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.table-responsive tr:hover {
  cursor: pointer;
}

.table-responsive tr:hover {
  background-color: lightgray !important;
}

.spinner {
    color: grey;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


/**
 * DASHBOARD
 */
.block-row {
    background-color: #eeeeee;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin: 20px 0 20px 0;
}

.info-block-header {
    border-bottom: 1px solid lightgrey;
    width: 100%;
    margin-bottom: 10px;
}

.info-block-header h2 {
    font-size: 18px;
    padding: 20px;
}

.info-block {
    /*text-align: center;*/
    padding: 15px;
}

.info-block .label {
    text-transform: uppercase;
}

.info-block .number {
    font-size: 2.5em;
    font-weight: bolder;
}

#mainMenu li.nav-item {
    text-align: center;
}
/**
	POPUP
 */

.popup-bg {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    padding: 10px;
    text-align: center;
    z-index: 2500;
}

.popup {
    top: 25%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    text-align: left;
    max-width: 800px;

    z-index: 2500;
    padding: 15px 25px;
    border-radius: 0px;
    border: 1px solid black;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    background-color: white;
}

.popup-content {
    z-index: 2500;
    padding: 15px 25px;
    border-radius: 5px;
    border: 1px solid black;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    background-color: white;
    text-align: left;
    position: relative;
}

.popup-content {
    margin: 0px auto;
    position: absolute;
    left: 50%;
    top: 50px;
    min-height: 200px;
    max-height: 90%;
    max-width: 90%;
    min-width: 300px;
    transform: translate(-50%, 0);
    overflow: auto;
}

.popup-content.middle {
    top: 50%;
    transform: translate(-50%, -50%);
}
.settings-page  .custom-btn-group .btn-outline-secondary{
    color: #2f2f2f;
    background-color: #c7c7c7;
}
.settings-page .custom-btn-group .btn-outline-secondary:not(:disabled):not(.disabled).active{
    background-color: #1e7e34;
    border-color: #1e7e34;
    color: #fff;
}
.settings-page .custom-btn-group .btn-outline-secondary:hover {
    background-color: #1e7e34;
    border-color: #1e7e34;
    color: #fff;
}
.settings-page .custom-btn-group .btn-outline-secondary:not(:disabled):not(.disabled).active:focus{
    box-shadow: 0 0 0 0.2rem rgba(72,180,97,.5);
}
.settings-page .custom-btn-group .btn-secondary.focus,
.settings-page .custom-btn-group .btn-secondary:focus,
.settings-page .custom-btn-group .btn-secondary:hover {
    background-color: #1e7e34;
    border-color: #1e7e34;
    color: #fff;
}
@media only screen and (min-width: 600px) {
    /* For tablets: */
    .navbar-brand {
        font-size: 0.9em;
    }

    /* hide mobile menu's on anything larger than mobile */
    #mainMenu .mobile, button.navbar-toggler,
    .BottomNav
    {
        display: none;
    }

    .loginblock {
        display: block;
    }
}


@media(max-width: 667px){
    .customers_text_center .col-md-12, .customers_text_center .col-md-6{
        text-align: center;
        padding: 0;
    }
}
/* #### Mobile Phones Portrait #### */
@media screen and (max-device-width: 480px) and (orientation: portrait){
    /* some CSS here */
    main{
        margin-bottom: 80px;
    }
}

/* #### Mobile Phones Landscape #### */
@media screen and (max-device-width: 640px) and (orientation: landscape){
    /* some CSS here */
}

/* #### Mobile Phones Portrait or Landscape #### */
@media screen and (max-device-width: 640px){
    /* some CSS here */
}

/* #### iPhone 4+ Portrait or Landscape #### */
@media screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2){
    /* some CSS here */
}

/* #### iPhone 5 Portrait or Landscape #### */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2){
    /* some CSS here */
}

/* #### iPhone 6 and 6 plus Portrait or Landscape #### */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3){
    /* some CSS here */
}

/* #### Tablets Portrait or Landscape #### */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
    /* some CSS here */
}

/* #### Desktops #### */
@media screen and (min-width: 1024px) {
    /* some CSS here */
}
