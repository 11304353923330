.UnitLine {
    padding: 1.25rem;

    h5 {
        font-size: 1rem;
    }

    &.deleting {
        color: lightgray;
    }

    &.deleted {
        display: none;
    }

    &.hovered {
        background-color: #d7efff;
    }

    .unit-creation-date {
        color: darkgray;
    }
}
