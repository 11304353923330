img.img-avatar {
  display: inline-block!important;
  width: 84px;
  height: 84px;
  border-radius: 50%;
}

.img-avatar-thumb {
  margin: .25rem;
  box-shadow: 0 0 0 0.25rem rgba(255,255,255,.3);
}

.bg-white {
  font-family: Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-weight: 400;
  line-height: 1.5;
  background-color: #edf0f2;
}

.font-size-h3 {
  color: #5179d6;
  font-size: 1.5rem;
}

.bg-image {
  background-image: url("/img/bospad.jpg");
  background-size: cover;
  //background-image: linear-gradient(90deg, #FF6700 , #F4EDED) url("/img/bridge.jpg");
  margin-top: -24px;
  margin-left: -24px;
  margin-right: -24px;
  width: calc(100% + 63px);
}

.bg-black-50 {
  background-color: rgba(0,0,0,.5)!important;
  height: 100%;
}

.content {
  width: 100%;
  margin: 0 auto;
  padding: 1.875rem 1.875rem 1px;
  overflow-x: visible;
}

.CustomerSingle {
}
