.sk-tooltip {
    position: relative;
    display: inline-block;
}

.sk-tooltip .sk-tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: black;
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-top: 5px;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.sk-tooltip:hover .sk-tooltiptext {
    visibility: visible;
    opacity: 1;
}
