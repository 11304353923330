.main-content {
    margin-bottom: 1rem !important;
}

@media screen and (max-width: 766px){
    /* add extra margin on mobile devices otherwise content falls behind the bottom nav */
    .main-content {
        margin-bottom: 7rem !important;
    }
}
