#groups {
    .group-header:hover {
        cursor: pointer;
        user-select: none;
    }
}

button.delete-group:hover {
    color: red
}

.customer-groups-datatable {
    .rdt_TableRow {
        font-size: 1rem;
    }
}

#groups.card {
    width: 450px;
}

@media screen and (max-width: 357px){
    #groups.card {
        width: 100px;
    }
}
