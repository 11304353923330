.Header {
    .navbar-brand {
        font-size: 1.25rem !important;
        line-height: inherit;
        white-space: nowrap;
    }

    #app-name {
        background-color: transparent;
    }

    .app-name {
        margin-top: -7px;
    }

    .version {
        color: #95999c;
        font-size: 0.7rem;
        margin-top: -5px;
    }

    .btn-logout {
        border: none;
        color: #ffffff;
    }

    .btn-logout {
        text-decoration: none;
    }
}
