.CustomerListView {
  .rdt_TableRow:hover {
    cursor: pointer;
    background-color: lightgrey;
  }
}

img.customer-image:hover {
    position: absolute;
    z-index: 10;
    width: 100px;
}
